import React from 'react';
import NextLink from 'next/link';
import {
	ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalHeader,
	Button, Text,
	Link as ChakraLink,
} from '@chakra-ui/react';
import textTW from 'utils/common.json'; // due to i18n remove temporarily

export function test() {
	return (
		<div> </div>
	);
}

export function ModalShowLoginSuccess({
	onClose, router, message,
}) {
	return (
		<>
			<ModalOverlay />
			<ModalContent>
				<ModalBody my={10} textAlign="center">
					<Text fontSize="lg">
						{message}
					</Text>
				</ModalBody>
				<ModalFooter mb={4} display="flex" justifyContent="center">
					<Button
						borderRadius="40px"
						colorScheme="orange"
						bgColor="pryOrange"
						size="md"
						width="80px"
						onClick={() => {
							onClose();
							router.replace('/login');
						}}
					>
						確定
					</Button>
				</ModalFooter>
			</ModalContent>
		</>
	);
}

export function ModalShowLoginFail({
	onClose, router, message,
}) {
	return (
		<>
			<ModalOverlay />
			<ModalContent>
				<ModalBody my={10} textAlign="center">
					<Text fontSize="lg">
						{message}
					</Text>
				</ModalBody>
				<ModalFooter mb={4} display="flex" justifyContent="center">
					<Button
						borderRadius="40px"
						colorScheme="orange"
						bgColor="pryOrange"
						size="md"
						width="80px"
						onClick={() => {
							onClose();
							router.replace('/');
						}}
					>
						確定
					</Button>
				</ModalFooter>
			</ModalContent>
		</>
	);
}

export function ModalShowAIStatement({
	onClose, start,
}) {
	return (
		<>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader mt={10}>
					<Text fontSize="2xl" textAlign="center">
						{textTW.thinkAI}
						說明
					</Text>
				</ModalHeader>
				<ModalBody my={4} px={10}>
					{/* {message?.split('/n').map((m) => (
						<Text my={4} fontSize="md">{m}</Text>
					))} */}
					<Text my={4} fontSize="md">
						此房間將會開啟
						{textTW.thinkAI}
						服務，所有想法皆有可能被傳送至
						{textTW.thinkAI}
						運算，請詳閱
						{textTW.thinkAI}
						使用條款，一旦開啟即表示您同意及接受
						<NextLink passHref href="/ai-terms">
							<ChakraLink
								borderBottom="1px solid #333"
								_hover={{ borderBottom: '1px solid #333' }}
								isExternal
							>
								{textTW.thinkAI}
								使用條款
							</ChakraLink>
						</NextLink>
						。
					</Text>
					<Text my={4} fontSize="md">
						{textTW.thinkAI}
						的產出僅作為參考的依據，無法保證皆完全正確無誤，且本網站不承擔使用
						{textTW.thinkAI}
						服務所造成之損失。
					</Text>
				</ModalBody>
				<ModalFooter mb={4} display="flex" justifyContent="center">
					<Button
						variant="outline"
						borderRadius="40px"
						colorScheme="orange"
						borderColor="pryOrange"
						color="pryOrange"
						size="md"
						mx={4}
						width="120px"
						onClick={onClose}
					>
						返回
					</Button>
					<Button
						borderRadius="40px"
						colorScheme="orange"
						bgColor="pryOrange"
						size="md"
						width="120px"
						onClick={() => {
							onClose();
							start();
						}}
					>
						下一步
					</Button>
				</ModalFooter>
			</ModalContent>
		</>
	);
}

import React, {
	useEffect, useState, useRef, useContext,
} from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import NextLink from 'next/link';
import {
	Container, Box, Flex, Link as ChakraLink, Heading, Stack, Text, Icon, Button, Image, Divider,
	useBreakpointValue, IconButton,
	useDisclosure, Drawer, DrawerContent, DrawerCloseButton, DrawerBody,
	Menu, MenuButton, MenuList, MenuItem, MenuDivider, Center,
	Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton,
	Tag,
} from '@chakra-ui/react';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
	faBars, faAngleDown, faPlus, faFlag, faUserCircle, faUser, faArrowRight, faSignOutAlt, faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useCookie } from 'react-use';
import 'focus-visible/dist/focus-visible';
import { signOut, useSession } from 'next-auth/react';
import { ContextStore } from '../contexts/authContext';
import axios from '../utils/axios';
import { ERROR_MESSAGE, COOKIES_EXPIRE_DAYS } from '../utils/constants';
import { LogoButton, LoadingAnimation } from './Unit';
import { ModalShowLoginSuccess } from './ModalShow';

export default function Home({
	title = '星可風暴', subtitle = '星可風暴', isNoBridge, isFull, authNeedPage, ...props
}) {
	const router = useRouter();
	const { userState, logout: contextLogout, refresh } = useContext(ContextStore);
	const mobileMenuShow = useBreakpointValue({
		base: true, md: false,
	});
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenLogin, onOpen: onOpenLogin, onClose: onCloseLogin } = useDisclosure();
	const btnRef = useRef();

	const [isLogin, setIsLogin] = useState(false);

	const logout = async () => {
		contextLogout();
		await signOut({ redirect: false });
		router.replace('/');
	};

	const [acceptCookie, setAcceptCookie] = useCookie('cck', { expires: COOKIES_EXPIRE_DAYS.CCK });

	useEffect(() => {
		if (userState.name) {
			setIsLogin(true);
		} else {
			setIsLogin(false);
		}
	}, [userState]);

	const { data: session } = useSession();

	useEffect(() => {
		refresh();
		if (authNeedPage && !userState.name) {
			router.push('/');
		}
		if (false && session && !userState.name) {
			onOpenLogin();
		}
	}, [router, session]);

	const [isVerifying, setIsVerifying] = useState(false);
	const [message, setMessage] = useState('');
	const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
	const initialRef = useRef();
	const resetStatus = () => {
		onCloseModal();
		setIsVerifying(false);
	};
	const verify = async () => {
		if (!isVerifying) {
			if (userState?.verify) {
				try {
					setIsVerifying(true);
					const result = await axios.get('/api/v1/user/eventperm');
					if (await result?.data?.eventPerm) {
						setTimeout(() => router.push('/start'), 1000);
					} else {
						onOpenModal();
						setMessage(ERROR_MESSAGE.NODETAILDATA);
					}
				} catch (e) {
					onOpenModal();
					switch (e?.response?.status) {
					case 401:
						setMessage(ERROR_MESSAGE.NOLOGIN);
						break;
					default:
						setMessage(ERROR_MESSAGE.DEFAULT);
					}
				}
			} else {
				onOpenModal();
				setMessage(ERROR_MESSAGE.NOVERIFY);
			}
		}
	};

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const handleStart = () => {
			if (isNoBridge) {
				setIsLoading(true);
			}
		};
		const handleStop = () => {
			setIsLoading(false);
		};

		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleStop);
		router.events.on('routeChangeError', handleStop);

		return () => {
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleStop);
			router.events.off('routeChangeError', handleStop);
		};
	}, [router]);

	const [isBgTransparent, setIsBgTransparent] = useState(true);
	useEffect(() => {
		document.addEventListener('scroll', () => {
			if (window.scrollY <= 0) {
				setIsBgTransparent(true);
			} else {
				setIsBgTransparent(false);
			}
		});
	}, []);

	return (
		<>
			<Head>
				<title>
					{title}
					{' '}
					|
					{' '}
					{subtitle}
				</title>
				<meta name="theme-color" content="red" />
			</Head>
			{isLoading ? (
				<LoadingAnimation height="100vh" />
			) : (
				<>
					<Container
						maxW="100%"
						mx="auto"
						bgColor={isBgTransparent ? 'transparent' : 'white'}
						sx={(!mobileMenuShow || !isOpen) && { position: 'sticky', top: '0', zIndex: 'sticky' }}
					>
						<Box maxW="container.xl" mx="auto" as="header">
							<Flex py={2} justifyContent="space-between" alignItems="center">
								{mobileMenuShow ? (
									<>
										<Flex w="100%" my={[2, 4]} align="center" justify="space-between">
											<LogoButton marginY={2} />
										</Flex>
										<IconButton
											display={isFull ? 'block' : 'none'}
											bgColor="transparent"
											color="#000"
											icon={<Icon as={Fa} icon={faBars} />}
											ref={btnRef}
											onClick={() => onOpen()}
											p={0}
											mx={0}
										/>
									</>
								) : (
									<LogoButton logoLinkClass="logo-link" logoImgClass="logo-img" marginY={4} />
								)}
								<Box as="nav" display={isFull ? 'flex' : 'none'}>
									{mobileMenuShow ? (
										<Drawer
											size="full"
											isOpen={isOpen}
											placement="right"
											onClose={onClose}
											finalFocusRef={btnRef}
										>
											<DrawerContent pt={6} px={4}>
												<DrawerCloseButton color="#000" mt={4} />
												<DrawerBody mx={0} px={0} pb={20}>
													<LogoButton marginX={2} />
													{isLogin && (
														<Flex py={4} my={4} justifyContent="center" alignItems="center" pl={3} borderBottom="1px solid" borderColor="lineGray">
															<Flex alignItems="center">
																<Icon as={Fa} icon={faUserCircle} size="4x" color="lineGray" mr={1} />
																<Text>{userState?.name}</Text>
															</Flex>
															<Tag ml={1} colorScheme="orange">星可+ Plus</Tag>
														</Flex>
													)}
													<Stack mt={4} direction="column" spacing={3}>
														<NextLink href="/pricing" passHref>
															<Box display="flex" flexDirection="column" alignItems="center">
																<Button mx={2} my={4} variant="link" color="pryOrange">
																	<Text fontSize="lg">價格方案</Text>
																</Button>
																<Divider />
															</Box>
														</NextLink>
														<NextLink href="/faq" passHref>
															<Box display="flex" flexDirection="column" alignItems="center">
																<Button mx={2} my={4} variant="link" color="pryOrange">
																	<Text fontSize="lg">常見問題</Text>
																</Button>
																<Divider />
															</Box>
														</NextLink>
														<NextLink href="/about" passHref>
															<Box display="flex" flexDirection="column" alignItems="center">
																<Button mx={2} my={4} variant="link" color="pryOrange">
																	<Text fontSize="lg">關於</Text>
																</Button>
																<Divider />
															</Box>
														</NextLink>
													</Stack>
													{isLogin ? (
														<Stack py={4} mt={4} direction="column" spacing={3} bgColor="pryOrange">
															<Box display="flex" flexDirection="column" alignItems="center">
																<Button mx={2} my={4} variant="link" color="#fff" onClick={verify}>
																	<Text fontSize="lg">建立新房間</Text>
																</Button>
																<Divider />
															</Box>
															<Box display="flex" flexDirection="column" alignItems="center">
																<NextLink href="/room" passHref>
																	<ChakraLink>
																		<Button mx={2} my={4} variant="link" color="#fff">
																			<Text fontSize="lg">房間紀錄</Text>
																		</Button>
																	</ChakraLink>
																</NextLink>
																<Divider />
															</Box>
															{/* <Box display="flex" flexDirection="column" alignItems="center">
																<NextLink href="/plan" passHref>
																	<Button mx={2} my={4} variant="link" color="#fff">
																		<Text fontSize="lg">星可+ Plus</Text>
																	</Button>
																</NextLink>
																<Divider />
															</Box> */}
															<Box display="flex" flexDirection="column" alignItems="center">
																<NextLink href="/user/profile" passHref>
																	<Button mx={2} my={4} variant="link" color="#fff">
																		<Text fontSize="lg">帳戶設定</Text>
																	</Button>
																</NextLink>
																<Divider />
															</Box>
															<Box display="flex" flexDirection="column" alignItems="center">
																<Button mx={2} my={4} variant="link" color="#fff" onClick={() => logout()}>
																	<Text fontSize="lg">登出</Text>
																</Button>
																<Divider />
															</Box>
														</Stack>
													) : (
														<Box mt={10} display="flex" flexDirection="column" alignItems="center">
															<NextLink href="/register" passHref>
																<ChakraLink>
																	<Button
																		borderLeftRadius="40px"
																		borderRightRadius="40px"
																		width="150px"
																		colorScheme="orange"
																		bgColor="pryOrange"
																		size="lg"
																	>
																		免費註冊
																	</Button>
																</ChakraLink>
															</NextLink>
															<NextLink href="/login" passHref>
																<ChakraLink>
																	<Button mx={2} mt={4} variant="link" color="pryOrange" size="lg">
																		登入
																	</Button>
																</ChakraLink>
															</NextLink>
														</Box>
													)}
												</DrawerBody>
											</DrawerContent>
										</Drawer>
									) : (
										<>
											<Flex borderRight="1px solid" borderColor="lineGray" pr={4}>
												<NextLink href="/pricing" passHref>
													<Button mx={4} variant="link" color="#000">價格方案</Button>
												</NextLink>
												<NextLink href="/faq" passHref>
													<Button mx={4} variant="link" color="#000">常見問題</Button>
												</NextLink>
												<NextLink href="/about" passHref>
													<Button mx={4} variant="link" color="#000">關於</Button>
												</NextLink>
											</Flex>
											<Flex ml={4} alignItems="center">
												{!isLogin ? (
													<>
														<NextLink href="/login" passHref>
															<ChakraLink>
																<Button mx={4} variant="link" color="#000">登入</Button>
															</ChakraLink>
														</NextLink>
														<NextLink href="/register" passHref>
															<ChakraLink>
																<Button
																	borderLeftRadius="20px"
																	borderRightRadius="20px"
																	width="120px"
																	colorScheme="orange"
																	bgColor="pryOrange"
																>
																	免費註冊
																</Button>
															</ChakraLink>
														</NextLink>
													</>
												) : (
													<Menu closeOnSelect={false}>
														<MenuButton as="Button">
															<Button
																borderLeftRadius="20px"
																borderRightRadius="20px"
																width="120px"
																colorScheme="orange"
																bgColor="pryOrange"
																rightIcon={<Icon mr={1} as={Fa} icon={faAngleDown} />}
																sx={{ zIndex: 'sticky' }}
															>
																我的房間
															</Button>
														</MenuButton>
														<MenuList>
															<Flex alignItems="center" pl={3}>
																<Icon as={Fa} icon={faUserCircle} size="1x" color="lineGray" mr={1} />
																<Text>{userState?.name}</Text>
																<Tag ml={1} size="sm" colorScheme="orange">星可+ Plus</Tag>
															</Flex>
															<MenuDivider />
															<MenuItem icon={<Icon as={Fa} icon={faPlus} color="pryOrange" />} onClick={verify}>
																建立新房間
															</MenuItem>
															<NextLink href="/room" passHref>
																<MenuItem icon={<Icon as={Fa} icon={faFlag} color="pryOrange" />}>房間紀錄</MenuItem>
															</NextLink>
															{/* <NextLink href="/plan" passHref>
																<MenuItem icon={<Icon as={Fa} icon={faArrowCircleUp} color="pryOrange" />}>星可+ Plus</MenuItem>
															</NextLink> */}
															<NextLink href="/user/profile" passHref>
																<MenuItem icon={<Icon as={Fa} icon={faUser} color="pryOrange" />}>帳戶設定</MenuItem>
															</NextLink>
															<MenuItem icon={<Icon as={Fa} icon={faSignOutAlt} color="pryOrange" />} onClick={() => logout()}>登出</MenuItem>
														</MenuList>
													</Menu>
												)}
											</Flex>
										</>
									)}
								</Box>
							</Flex>
							{(isFull) && <Divider />}
						</Box>
					</Container>
					<Box>
						{props.children}
					</Box>
					<Box as="footer" bgColor="bgGray" display={isFull ? 'block' : 'none'}>
						<Container maxW="container.xl" mt={10} p={10}>
							<Flex flexDirection={['column', 'row']} justifyContent={['flex-start', 'space-between']}>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Flex alignItems="center">
										<Image src="/logo_bhis.svg" width="32px" alt="BHIS" mr={3} />
										<Heading fontSize="md" textAlign="center">BHIS</Heading>
									</Flex>
									{mobileMenuShow && (
										<Text color="#000" fontSize="xs" fontWeight="bold">
											Copyright © 2020~
											{new Date().getFullYear()}
										</Text>
									)}
								</Box>
								{mobileMenuShow && <Divider my={4} borderColor="lineGray" border="1px solid" />}
								<Flex direction={['column', 'row']}>
									<Box mx={[0, 10]} mt={[6, 0]} display="flex" flexDirection="column">
										<Heading fontSize="md" mb={2} color="#000">相關連結</Heading>
										<ChakraLink href="https://data.gov.tw" isExternal>
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">政府開放資料平臺</Button>
										</ChakraLink>
										<ChakraLink href="https://data.gov.tw/license" isExternal>
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">政府資料開放授權條款</Button>
										</ChakraLink>
									</Box>
									<Box mx={[0, 10]} mt={[6, 0]} display="flex" flexDirection="column">
										<Heading fontSize="md" mb={2} color="#000">其它服務</Heading>
										<ChakraLink href="https://odportal.tw" isExternal>
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">ODPortal 臺灣資料集散地</Button>
										</ChakraLink>
										<ChakraLink href="https://odportal.tw/playground/nt" isExternal>
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">星可全開</Button>
										</ChakraLink>
										<ChakraLink href="https://cloud.datathink.design" isExternal>
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">黛塔雲</Button>
										</ChakraLink>
										<ChakraLink href="https://sdg.tw" isExternal>
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">SDGIndicators</Button>
										</ChakraLink>
									</Box>
									<Box mx={[0, 10]} mt={[6, 0]} display="flex" flexDirection="column">
										<Heading fontSize="md" mb={2} color="#000">關於我們</Heading>
										<ChakraLink href="https://www.bhis.com.tw" isExternal>
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">官方網站</Button>
										</ChakraLink>
										<ChakraLink href="mailto:service@bhis.com.tw" isExternal>
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">聯絡我們</Button>
										</ChakraLink>
										<ChakraLink href="/privacy">
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">隱私權政策</Button>
										</ChakraLink>
										<ChakraLink href="/terms">
											<Button mt={[0, 2]} variant="link" size="sm" color="#000">服務條款</Button>
										</ChakraLink>
										<NextLink passHref href="/ai-terms">
											<ChakraLink mt={[0, 2]} fontSize="sm" color="#000" fontWeight="bold">
												星可 AI 使用條款
											</ChakraLink>
										</NextLink>
									</Box>
								</Flex>
							</Flex>
							{!mobileMenuShow ? (
								<>
									<Divider my={4} borderColor="lineGray" border="1px solid" />
									<Text mt={4} color="#333" fontSize="xs">
										Copyright © 2020~
										{new Date().getFullYear()}
									</Text>
								</>
							) : null}
						</Container>
					</Box>
					{!acceptCookie && (
						<Box
							display="flex"
							flexDirection={['column', 'row']}
							justifyContent={['flex-start', 'center']}
							zIndex={2}
							width="100vw"
							px={[10, 20]}
							py={[2, 10]}
							position="fixed"
							bottom={0}
							bgColor="#fff"
							shadow="xl"
							border="1px solid"
							borderColor="lineGray"
						>
							<Box maxW={['auto', '50%']}>
								<Text textAlign="justify" fontWeight="bold" fontSize={['sm', 'md']}>
									我們使用 Cookies 為您提供更好的用戶體驗，請參閱本網站「
									<NextLink href="/privacy" passHref>
										<ChakraLink textDecor="underline">
											隱私權政策
										</ChakraLink>
									</NextLink>
									」之 Cookie 聲明，繼續使用我們的服務即表示您已閱讀、理解並同意我們的 Cookie 政策。
								</Text>
							</Box>
							<Center>
								<Button
									mx={10}
									my={2}
									fontSize={['sm', 'md']}
									borderLeftRadius="20px"
									borderRightRadius="20px"
									width={['100px', '120px']}
									colorScheme="orange"
									bgColor="warning"
									onClick={() => setAcceptCookie('yes', { expires: COOKIES_EXPIRE_DAYS.CCK })}
								>
									我知道了
								</Button>
							</Center>
						</Box>
					)}
				</>
			)}
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpenModal}
				onClose={resetStatus}
				size={mobileMenuShow ? 'xs' : 'md'}
				initialFocusRef={initialRef}
				isCentered
			>
				<ModalProfileNeed message={message} isLogin={isLogin} initialRef={initialRef} router={router} email={userState?.email} />
			</Modal>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpenLogin}
				onClose={onCloseLogin}
				size="md"
				isCentered
			>
				<ModalShowLoginSuccess onClose={onCloseLogin} router={router} message="綁定成功" />
			</Modal>
			<style jsx global>
				{`
					.logo-link .logo-img {
						transition: all 0.2s ease;
					}
					.logo-link:hover .logo-img {
						transform: rotate3d(0, 1, 0, 360deg);
					}
					.js-focus-visible button:focus, .js-focus-visible a:focus {
						box-shadow: 0 0 0 1px #BEBEBE;
					}
					.js-focus-visible button:focus:not(.focus-visible), .js-focus-visible a:focus:not(.focus-visible) {
						outline: none;
					}
				`}
			</style>
		</>
	);
}

function ModalProfileNeed({
	message, isLogin, initialRef, router, email,
}) {
	const [isClickSendMail, setIsClickSendMail] = useState(false);
	const [isSendMail, setIsSendMail] = useState(false);
	const [error, setError] = useState('');
	return (
		<>
			<ModalOverlay />
			<ModalContent p={10}>
				<ModalCloseButton px={10} py={5} position="absolute" top="-20" left="40%" color="white" borderColor="white" border="1px solid" borderRadius="40px" />
				<ModalBody mt={10} textAlign="center">
					{message?.split('/n').map((m) => (
						<Text fontSize={['md', 'lg']} fontWeight="bold">{m}</Text>
					))}
				</ModalBody>
				{isLogin ? (
					<ModalFooter display="flex" flexDirection="column" justifyContent="center">
						<Button
							borderRadius="40px"
							colorScheme="orange"
							bgColor="pryOrange"
							size="md"
							rightIcon={(
								<Icon
									as={Fa}
									// icon={isSendMail ? faCheck : faArrowRight}
									icon={isSendMail ? faCheck : faArrowRight}
								/>
							)}
							ref={initialRef}
							onClick={() => {
								setIsClickSendMail(true);
								if (message === ERROR_MESSAGE.NODETAILDATA) {
									setTimeout(() => router.push('/user/profile'), 500);
								} else if (message === ERROR_MESSAGE.NOVERIFY) {
									try {
										axios.get('/api/v1/user/reverify');
										setTimeout(() => {
											setIsSendMail(true);
										}, 1000);
									} catch (e) {
										setTimeout(() => {
											setError('設定新密碼失敗，請稍後再試。');
										}, 1000);
									}
								}
							}}
							isDisabled={isSendMail}
							isLoading={isClickSendMail && !isSendMail}
						>
							{!isSendMail ? (
								<Text>
									{message === ERROR_MESSAGE.NODETAILDATA ? '前往填寫' : message === ERROR_MESSAGE.NOVERIFY ? '發送驗證信' : ''}
								</Text>
							) : (
								<Text>
									已寄送
								</Text>
							)}
						</Button>
						{isSendMail && (
							<Text size="xs" textAlign="center" mt={2}>
								已發送驗證信至
								{' '}
								{email}
							</Text>
						)}
						{error !== '' && (
							<Text size="xs" textAlign="center" mt={2} color="warning">
								{error}
							</Text>
						)}
					</ModalFooter>
				) : null}
			</ModalContent>
		</>
	);
}

import React from 'react';
import NextLink from 'next/link';
import Router from 'next/router';
import {
	Button, Icon, Text, Link as ChakraLink, Heading, Flex, Image,
	Box, Divider,
	InputGroup, Input, InputRightElement,
	Container, SkeletonCircle, SkeletonText,
	Stack, Tooltip, Wrap, WrapItem,
} from '@chakra-ui/react';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
	faQuestionCircle, faUserTimes, faArrowRight,
	faPlay, faPause, faPlus, faFastForward, faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import animalTW from 'utils/animal.json'; // due to i18n remove temporarily
import textTW from '../utils/common.json'; // due to i18n remove temporarily

export function test() {
	return (
		<div> </div>
	);
}

export function ButtonNotEnough() {
	return (
		<Button ml={2} variant="outline" color="warning" borderColor="warning" size="xs" colorScheme="orange">
			容納人數不夠
			<Icon ml={1} color="warning" variant="solid" icon={<Fa icon={faQuestionCircle} />} />
		</Button>
	);
}

export function goToTop() {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
}

export function LogoButton({
	logoLinkClass, logoImgClass, marginX, marginY,
}) {
	return (
		<NextLink href="/">
			<ChakraLink _hover={{ textDecor: 'none' }} className={logoLinkClass}>
				<Flex mx={marginX} my={marginY}>
					<Image src="/logo.png" width="20px" height="26px" alt="Logo" mr={2} className={logoImgClass} />
					<Heading as="h1" fontWeight={800} fontSize="lg" color="pryOrange">星可風暴</Heading>
				</Flex>
			</ChakraLink>
		</NextLink>
	);
}

export function RoomNotExistedShow({ closeMsg }) {
	return (
		<Flex w="100%" h="80vh" flexDirection="column" justifyContent="center" alignItems="center" mb={4}>
			{closeMsg.split('/n').map((s) => (
				<Text fontSize="xl" fontWeight="bold">{s}</Text>
			))}
		</Flex>
	);
}

export function RoomUserBannedShow({ bannedMsg }) {
	return (
		<Box h="80vh" d="flex" flexDirection="column" justifyContent="center" alignItems="center">
			<Flex justifyContent="center" alignItems="center" pb={20}>
				<Icon mr="4" as={Fa} icon={faUserTimes} size="4x" />
				<Text fontWeight="bold" fontSize="xl">{bannedMsg}</Text>
			</Flex>
			<Divider w="80%" border="1px solid" />
			<NextLink href="/" passHref>
				<Button
					width="120px"
					borderLeftRadius="40px"
					borderRightRadius="40px"
					bgColor="pryOrange"
					colorScheme="orange"
					size="md"
					py={4}
					mt={6}
				>
					返回主畫面
				</Button>
			</NextLink>
		</Box>
	);
}

export function RoomUserFullShow({
	fullMsg, isCheckedRoom, setIsCheckedRoom, text, setText, router,
}) {
	return (
		<Box h="80vh" d="flex" flexDirection="column" justifyContent="center" alignItems="center">
			<Flex justifyContent="center" alignItems="center" pb={20}>
				<Icon mr="4" as={Fa} icon={faUserTimes} size="4x" />
				<Box>
					{fullMsg.split('/n').map((s) => (
						<Text fontWeight="bold" fontSize="xl">{s}</Text>
					))}
				</Box>
			</Flex>
			<Divider w="80%" border="1px solid" />
			<Box mt={5}>
				<Text
					color={!isCheckedRoom ? 'black' : text !== router?.query?.id ? 'black' : 'warning'}
					textAlign="center"
					my={6}
				>
					{!isCheckedRoom ? '加入其它房間' : text !== router?.query?.id ? '加入其它房間' : '請輸入其它房間代號'}
				</Text>
				<InputGroup size="md">
					<Input
						pr="4.5rem"
						h="3rem"
						type="text"
						size="sm"
						fontSize="sm"
						border="2px solid"
						borderColor="pryOrange"
						borderLeftRadius="20px"
						borderRightRadius="20px"
						placeholder="輸入房間代碼"
						value={text}
						onChange={(e) => { setText(e.target.value.toUpperCase()); setIsCheckedRoom(false); }}
						onKeyDown={(e) => { if (e.key === 'Enter') Router.push(`/session?id=${text}`); }}
						position="relative"
					/>
					<InputRightElement width="4.5rem">
						<Button
							px={6}
							h="3rem"
							size="sm"
							borderLeftRadius="20px"
							borderRightRadius="20px"
							bgColor="pryOrange"
							colorScheme="orange"
							position="absolute"
							right={0}
							top={0}
							rightIcon={<Icon mr={1} as={Fa} icon={faArrowRight} />}
							onChange={(e) => setText(e.target.value.toUpperCase())}
							onClick={() => {
								setIsCheckedRoom(true);
								if (text !== '' && text !== router.query.id) {
									Router.push(`/session?id=${text}`);
								}
							}}
							onKeyDown={(e) => { if (e.key === 'Enter' && text !== '') Router.push(`/session?id=${text}`); }}
						>
							加入
						</Button>
					</InputRightElement>
				</InputGroup>
			</Box>
		</Box>
	);
}

export function Skeleton() {
	return (
		<Container maxW="container.lg" my={10}>
			<Box p={6} boxShadow="lg" bg="white">
				<SkeletonCircle size="10" />
				<SkeletonText mt={4} noOfLines="4" spacing="4" skeletonHeight="2" />
			</Box>
			<Box p={6} boxShadow="lg" bg="white">
				<SkeletonCircle size="10" />
				<SkeletonText mt={4} noOfLines="4" spacing="4" skeletonHeight="2" />
			</Box>
			<Box p={6} boxShadow="lg" bg="white">
				<SkeletonCircle size="10" />
				<SkeletonText mt={4} noOfLines="4" spacing="4" skeletonHeight="2" />
			</Box>
			<Box p={6} boxShadow="lg" bg="white">
				<SkeletonCircle size="10" />
				<SkeletonText mt={4} noOfLines="4" spacing="4" skeletonHeight="2" />
			</Box>
			<Box p={6} boxShadow="lg" bg="white">
				<SkeletonCircle size="10" />
				<SkeletonText mt={4} noOfLines="4" spacing="4" skeletonHeight="2" />
			</Box>
			<Box p={6} boxShadow="lg" bg="white">
				<SkeletonCircle size="10" />
				<SkeletonText mt={4} noOfLines="4" spacing="4" skeletonHeight="2" />
			</Box>
		</Container>
	);
}

export function LoadingAnimation({ height }) {
	return (
		<>
			<Flex w="100%" h={height} flexDirection="column" justifyContent="center" alignItems="center" mb={4}>
				<Image src="/logo.png" width="20px" height="26px" alt="Logo" mr={2} className="animated-swing" />
			</Flex>
			<style jsx global>
				{`
					.animated-swing{
						animation: swing ease-in-out 0.3s infinite alternate;
						transform-origin: center center;
					}
					@keyframes swing {
						0% { transform: rotate(20deg); }
						100% { transform: rotate(-20deg); }
					}
				`}
			</style>
		</>
	);
}

export function RoomAdminControlPanel({
	event, socket, isMobileDevice, onOpenStopRoom, router,
}) {
	return (
		<Stack ml={4} direction="row" spacing={2}>
			{event.pause ? (
				<Button
					w={['20px', 'auto']}
					borderRadius={isMobileDevice ? '50%' : '40px'}
					colorScheme="orange"
					bgColor="pryOrange"
					size="md"
					onClick={() => socket.emit('resumeSession', { eid: event.id })}
				>
					<Icon as={Fa} icon={faPlay} mx={2} />
					{/* {t('sessionResume')} */}
					{!isMobileDevice && textTW.sessionResume}
				</Button>
			) : (
				<Button
					w={['20px', 'auto']}
					borderRadius={isMobileDevice ? '50%' : '40px'}
					colorScheme="orange"
					bgColor="pryOrange"
					size="md"
					onClick={() => socket.emit('pauseSession', { eid: event.id })}
				>
					<Icon as={Fa} icon={faPause} mx={2} />
					{/* {t('sessionPause')} */}
					{!isMobileDevice && textTW.sessionPause}
				</Button>
			)}
			<Button
				w={['20px', 'auto']}
				borderRadius={isMobileDevice ? '50%' : '40px'}
				colorScheme="orange"
				bgColor="pryOrange"
				size="md"
				onClick={() => socket.emit('setTime', { eid: router.query?.id, time: event.time + 60 })}
			>
				<Icon as={Fa} icon={faPlus} mx={2} />
				{/* {t('sessionAddOneMin')} */}
				{!isMobileDevice && textTW.sessionAddOneMin}
			</Button>
			{event?.labels?.length >= 2 ? (
				<Button
					w={['20px', 'auto']}
					borderRadius={isMobileDevice ? '50%' : '40px'}
					colorScheme="orange"
					bgColor="pryOrange"
					size="md"
					onClick={() => socket.emit('setTime', { eid: router.query?.id, time: 3 })}
				>
					<Icon as={Fa} icon={faFastForward} mx={2} />
					{/* {t('sessionSkipStage')} */}
					{!isMobileDevice && textTW.sessionSkipStage}
				</Button>
			) : null}
			<Button
				variant="outline"
				w={['20px', 'auto']}
				borderRadius={isMobileDevice ? '50%' : '40px'}
				borderColor="pryOrange"
				colorScheme="orange"
				color="pryOrange"
				size="md"
				onClick={onOpenStopRoom}
			>
				<Icon as={Fa} icon={faStopCircle} mx={2} />
				{/* {t('sessionAddOneMin')} */}
				{!isMobileDevice && textTW.sessionStopRoom}
			</Button>
		</Stack>
	);
}

export function RoomParticipant({
	event, socket, isMobileDevice, Avatar, setBanPeopleId, onOpenBanned,
}) {
	return (
		<Box my={8}>
			{/* <Text>{t('sessionParticipant')}</Text> */}
			{/* <Text>{textTW.sessionParticipant}</Text> */}
			<Flex mb={2} alignItems="flex-end">
				<Text>目前已有</Text>
				<Text fontWeight="bold" fontSize="2xl" mx={2}>{event?.people?.filter((p) => p.alive).length}</Text>
				<Text>
					位參加者
					{!isMobileDevice && event.admin === socket.auth.sessionId && '，還可以容納'}
				</Text>
				{!isMobileDevice && event.admin === socket.auth.sessionId ? (
					<>
						<Text fontWeight="bold" fontSize="2xl" mx={2}>
							{event.maxPeople - event.people.filter((p) => p.alive).length ?? 0}
						</Text>
						<Text>位</Text>
						{event.people.length >= event.maxPeople && <ButtonNotEnough />}
					</>
				) : null }
			</Flex>
			<Wrap display="flex" alignItems="center">
				{event.people.filter((u) => u.alive).map((u) => (
					// <Tooltip label={t(`animal:${u.name}`)} key={u.id}>
					<Tooltip
						label={
							`${animalTW[u.name]
								?? u.name}${u.id === event.admin ? '（主持人）' : u.id === socket.auth.sessionId ? '（我）' : ''}`
						}
						key={u.id}
					>
						<WrapItem m={2} display="flex" alignItems="center">
							{event.admin === socket.auth.sessionId && u.id !== event.admin ? (
								<Button
									m={0}
									p={0}
									variant="unstyled"
									onClick={() => {
										setBanPeopleId(u.id);
										onOpenBanned();
									}}
									display="flex"
									justifyContent="center"
								>
									<Avatar uid={u.id} w={isMobileDevice ? 30 : 40} />
								</Button>
							) : (
								<Box
									w="40px"
									m={0}
									p={0}
									display="flex"
									justifyContent="center"
								>
									<Avatar uid={u.id} w={isMobileDevice ? 30 : 40} />
								</Box>
							)}
						</WrapItem>
					</Tooltip>
				))}
			</Wrap>
		</Box>
	);
}

export const EVENT_STAGE = Object.freeze({
	CLOSED: -1,
	LABELING: 0,
	BRAINSTORMING: 1,
	VOTING: 2,
	SUMMARIZE: 3,
});

export const ACTION_STATUS = Object.freeze({
	DEFAULT: 0,
	LOADING: 1,
	SUCCESS: 2,
	ERROR: -1,
});

export const ERROR_MESSAGE = Object.freeze({
	DEFAULT: '系統發生非預期錯誤，請稍後再試或洽系統管理員',
	NOLOGIN: '尚未登入，請登入後再試',
	NOVERIFY: '信箱尚未驗證，請重新驗證',
	VERIFIEDFAIL: '驗證失敗，請稍後再試。',
	NODETAILDATA: '新增房間前請先至帳戶設定/n填寫個人基本資料',
	USEREXIST: '此帳號已被註冊',
	USERACCOUNTWRONG: '帳號或密碼錯誤，請確認後再試',
	USERACCOUNTEMPTY: '帳號、密碼不能為空',
	USERORINGPWDWRONG: '舊密碼輸入錯誤',
	USERPERMISSIONDENIED: '無編輯權限',
	USERDATANOEXIST: '帳號不存在',
	EVENTNOEXIST: '房間不存在',
	NAMELENGTHOVERLIMIT: '名稱長度超過限制',
	NAMEEMPTY: '請輸入名稱',
	LOGINDIFFRENT: '與上次登入方式不同，請確認後再試',
	LOGINNOEMAIL: '社群帳號未驗證，請確認後再試'
});

export const COUNTDOWN_TIME = Object.freeze({
	OOC: 60, // 在產出個數偏少的情況下，檢查目前時間是否少於這個秒數，是的話則跳出提醒視窗
	OOS: 30, // 在產出個數偏少的情況下，檢查目前時間是否少於這個秒數，是的話倒數時間開始閃爍
	LASTREMAINING: 1, // 在產出個數偏少的情況下，檢查目前時間是否少於這個秒數，是的話就終止房間
});

// 產出個數判斷依據為 LEAST_OUTPUT_COUNT
export const LEAST_OUTPUT_COUNT = Object.freeze({
	LABELING: 2, // 發想階段產出標籤數至少要產出個數
	BRAINSTORMING: 1, // 組合階段產出想法數至少要產出個數
});

export const SAVE_NUMBER_LIMIT = Object.freeze({
	NORMAL: 10,
	PRO: 50,
	ENTERPRISE: Number.MAX_VALUE,
});

export const ROOM_COUNT_PER_PAGE = Object.freeze({
	FIRST: 8,
	OTHER: 9,
});

export const ROOM_TEXT_MAXIMUM = Object.freeze({
	TITLE: 28,
	DESC: 100,
	NAME: 10,
});

export const COOKIES_EXPIRE_DAYS = Object.freeze({
	CCK: 365,
});

export const ROOM_STATUS = Object.freeze({
	ALL: '所有房間紀錄',
	HOST: '我開過的房間',
	PARTICIPATED: '我參加過的房間',
});

export const AI_SHOW_STATUS = Object.freeze({
	DEFAULT: 0, // 初始狀態
	HOVER: 1, // 滑鼠滑過
	CLICK: 2, // 點擊
	LOADING: 3, // AI 等待中
	COOLINGDOWN: 4, // 復原中
	RUNOUT: -1, // 次數用完
});

export const AI_THINKTIME = Object.freeze({
	LABELING: 7, // 發想階段用AI產出標籤的間隔時間
	BRAINSTORMING: 7, // 發想階段用AI產出標籤的間隔時間
});

export const AI_THINK_LIMIT = Object.freeze({
	NORMAL: 3,
	PRO: 10,
});
